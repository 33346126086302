import React, { useEffect, useState } from 'react'
import { View, Image, ImageSourcePropType, } from 'react-native'
import { IStores } from '../../state/store'
import { useSelector } from 'react-redux'
import Text from '../text'
import Block from './Block'

interface Qrs { [key: string]: ImageSourcePropType, }
const qrs: Qrs = {
  //
}

interface IRefererBlockProps {
  masterRef?: any,
  order: number,
}
function RefererBlock({ order, }: IRefererBlockProps) {
  const systemStore = useSelector((state: IStores) => state.systemStore)
  const { mobile, } = systemStore
  const { Colors, Fonts, Spacing, } = systemStore.mobile ? systemStore.Mobile : systemStore.Desktop

  const [referer, setReferer] = useState<string | null>(null)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const ref = params.get('ref')
    if (ref) setReferer(ref)
  }, [])

  const qr = referer ? qrs[referer] || qrs.default : qrs.default

  return (
    <>
      <Block order={order}>
        <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',}}>
          <Text style={{fontSize: Fonts.xxl, fontWeight: Fonts.middleWeight, marginBottom: 8, color: Colors.white,}}>Streetpass</Text>

          <Image
            source={qr}
            style={{width: 256, height: 256,}}
          />
        </View>

        <View style={{position: 'absolute', width: '100%', justifyContent: 'center', alignItems: 'center', bottom: 8,}}>
          <Text style={{fontSize: 12, fontWeight: Fonts.featherWeight, color: Colors.white,}}>© {new Date().getFullYear()} Streetpass | Kite App Inc | All rights reserved</Text>
        </View>
      </Block>
    </>
  )
}

export default RefererBlock
