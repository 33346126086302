import React, { useRef, } from 'react'
import NavTabBar from '../components/navTabBar'
import BlockBox from '../components/blocks/BlockBox'
import RefererBlock from '../components/blocks/RefererBlock'
import { Screens } from '../App'

export interface IReferProps {

}
function Refer({ }: IReferProps) {
  const masterRef = useRef<any>(null)

  return (
    <>
      <NavTabBar screen={Screens.Refer} />

      <BlockBox masterRef={masterRef}>
        <RefererBlock order={0} />
      </BlockBox>
    </>
  )
}

export default Refer
