let env: string
env = 'prod'
// env = 'dev'
export const baseUrl = (env === 'prod') ? 'streetpass.app' : '192.168.0.11:8080' // 'localhost:8080' // development simulator, '192.168.0.11:8080' // development home, '10.0.0.2:8080' // development away
export const protocol = (env === 'prod') ? { 0: 'https://', 1: 'wss://', } : { 0: 'http://', 1: 'ws://', }

export const email = 'info@streetpass.app'
export const appStoreListing = 'https://apps.apple.com/app/6476482031'
export const compact = '/assets/media/compact.png'
export const thumbnail = '/assets/media/thumbnail.png'
export const video = 'https://kite-case.s3.us-east-2.amazonaws.com/landing-c.mp4'
export const gif = 'https://kite-case.s3.us-east-2.amazonaws.com/landing-a.gif'

export interface ISchoolConfig {
  logo: any,
  logoAlt: any,
  tone: string,
  highlight: string,
  headlines: Array<string>,
  testimonials: Record<string, string>,
}

export const schoolConfigs: Record<string, ISchoolConfig> = {
  ubc: {
    logo: require('../assets/media/ubc.png'),
    logoAlt: require('../assets/media/ubc-alt.jpg'),
    tone: '#0055B7',
    highlight: '#FFEA00',
    headlines: [
      'Find your Rose Garden romance.\nStreetpass: where UBC hearts connect.',
      'Missed sparks at the Nest?\nLet Streetpass be your second chance!',
      'Skip the line at Blue Chip, not your chance at love.\nDiscover Streetpass.',
      'From Buchanan to Biolabs, make every walk to class count with Streetpass.',
      'Thunderbird catching your eye?\nCatch them for real on Streetpass.',
      'Wreck Beach wasn\'t the only stunning view today.\nFind them on Streetpass!',
      'Is your perfect match in the Irving K. stacks?\nStreetpass knows.',
      'Beat the UBC bus rush—find a study date on your route with Streetpass.',
      'Not just another face in the lecture hall.\nSay hello with Streetpass.',
      'Sauder saw a spark?\nMix business and pleasure on Streetpass.',
      'Engineering connections stronger than the EUS Bridge.\nTry Streetpass.',
      'Missed a connection at the Totem Park Dining Hall?\nStreetpass has got you covered.',
      'Forget the fog, your love forecast is clear with Streetpass at UBC.',
      'AMS event alone?\nNext time, bring a date from Streetpass.',
      'From one Thunderbird to another, let Streetpass wingman your love life.',
      'Cramming in Koerner?\nDecompress with a date, thanks to Streetpass.',
      'Your study buddy in Scarfe could be more than a classmate with Streetpass.',
      'Caught a cute smile at Chan Centre?\nEncore with Streetpass.',
      'Love at first hike?\nMeet them at Pacific Spirit Park with Streetpass.',
    ],
    testimonials: {
      'Mia, 18': `I used to see him grabbing coffee every morning, but we never talked. Streetpass connected us, and now we make coffee runs together—and he makes me laugh every time.`,
      'Dan, 20': `I passed her at Rec Beach last summer and thought I'd never see her again. Streetpass brought us back together and we just went on a picnic date to the beach.`,
      'Izzie, 21': `I always noticed him studying in the courtyard by the Earth Sciences Building. When we matched on Streetpass, it was like destiny.`,
      'Jordan, 24': `We kept crossing paths at the gym, but I never thought she'd notice me. After we matched on Streetpass, I found out she was thinking the same thing I was and now we're together!`,
      'Karissa, 20': `I saw her reading in the Rose Garden. Then I saw her on Streetpass and I recognized her immediately. So I swiped right, and we matched! Thank you for making this app!`,
      'Sandra, 23': `I saw him practicing soccer on the turf and couldn't help but admire his talent. Streetpass matched us, and now I get to admire him every day.`,
      'Jamie, 19': `She sat across from me in the food court at the Nest for weeks, and I never had the courage to say hi. I guess Streetpass gave me the push I needed. After we matched on the app, I asked her out in real life.`,
      'Jessica, 22': `I noticed him at AMS movie night and really wanted to talk to him but he was busy with his friends. We matched on Streetpass, and now I get him all to myself.`,
    },
  },
  sfu: {
    logo: require('../assets/media/sfu.png'),
    logoAlt: require('../assets/media/sfu-alt.png'),
    tone: '#e7335b',
    highlight: '#e7335b',
    headlines: [
      'Harbour Centre views, Harbour Centre crushes.\nConnect on Streetpass.',
      'From the steps of SFU to the streets of Gastown, find your match with Streetpass.',
      'Missed a connection in the crowded halls of Segal?\nStreetpass is here to help.',
      'Your love story could be a SkyTrain ride away.\nDiscover them on Streetpass.',
      'Library silent zones can\'t silence the chemistry.\nFind your whisper-quiet match on Streetpass.',
      'Forget the rain at SFU Burnaby; spark something sunny on Streetpass.',
      'Woodward\'s might host your next lecture, but Streetpass hosts your next date.',
      'Studying downtown?\nBreak from the books and meet someone new with Streetpass.',
      'Coffee at West Hastings?\nMake it a date with Streetpass.',
      'Turn a chance glance at Goldcorp into a golden connection with Streetpass.',
      'From film night at Djavad Mowafaghian to real romance—only on Streetpass.',
      'Your study spot in Belzberg Library could be two seats away from a date. Check Streetpass.',
      'Missed a spark at SFU Contemporary Arts?\nRekindle it on Streetpass.',
      'Don\'t let foggy days blur your love prospects.\nClear up your romantic outlook with Streetpass at SFU.',
      'Art, architecture, and attraction—find all three in Vancouver\'s heart with Streetpass.',
      'Connect your circuits at Applied Sciences.\nStart with a spark on Streetpass.',
      'Caught eyeing someone at SFU Public Square?\nIt\'s time to Streetpass.',
      'A ride down Burnaby Mountain need not be lonely—find your companion on Streetpass.',
      'Meet your match faster than you can walk from Harbour Centre to Waterfront—only on Streetpass.',
    ],
    testimonials: {
      'Alex, 20': `I always saw her grabbing coffee between classes but never had the courage to approach. Streetpass was the perfect icebreaker.`,
      'Maya, 22': `We kept bumping into each other at the library, making eye contact but never talking to each other. Matched on Streetpass and we've been dating for about half a year now.`,
      'Ryan, 24': `I saw her at the top of Burnaby Mountain during sunset, and I thought I missed my chance when I waited to long to talk to her before she drove off. We matched on Streetpass the next day!`,
      'Sophia, 21': `We passed each other so many times at the Segal building, and I always wondered if he noticed me too. He messaged me on Streetpass and I couldn't believe it, but turns out he was wondering the same as me!`,
      'Ethan, 23': `I noticed her studying in the library but didn't want to interrupt. We matched and started talking! I don't think I woulda got this opportunity without Streetpass.`,
      'Daniel, 25': `She sat next to me during a Public Square event, but I was too shy to start a conversation. Saw her on Streetpass and got the second chance I needed.`,
      'Emma, 23': `I used to see him going for lunch between classes. Streetpass made it possible for us to connect without going out of our way or risking major embarassment!`,
    },
  },
  uvic: {
    logo: require('../assets/media/uvic.png'),
    logoAlt: require('../assets/media/uvic-alt.png'),
    tone: '#005493',
    highlight: '#ff5484',
    headlines: [
      'From Mystic Market to a magical moment.\nFind love on Streetpass.',
      'Lost in the Ring Road maze?\nFind your way to someone\'s heart with Streetpass.',
      'Finnerty\'s coffee pairs best with charming company.\nMeet them on Streetpass.',
      'Missed a spark in McPherson Library?\nRekindle it with Streetpass.',
      'Catching eyes in CARSA?\nConvert glances into plans with Streetpass.',
      'Not all the stars are at the Observatory.\nSpot your celestial match on Streetpass.',
      'Cadboro Bay outings are better with two.\nFind your beach buddy on Streetpass.',
      'Gardens are for wandering, hearts are for wondering.\nConnect on Streetpass.',
      'Bus loop missed connection?\nCatch them next time with Streetpass.',
      'Your study break at BiblioCafé could brew more than coffee with Streetpass.',
      'Turn a stroll through Alumni Chip Trail into a romantic walk with Streetpass.',
      'Engineering love connections stronger than the EOW building. Find them on Streetpass.',
      'UVIC\'s green spaces are perfect for blue-sky thinking and new romances. Discover on Streetpass.',
      'From lecture halls to Lampson streets, your next crush could be a click away on Streetpass.',
      'Biomedical? Environmental?\nYour field of study isn\'t the only match to make at UVIC with Streetpass.',
      'Missed a cute smile during class in Elliot?\nSmile back on Streetpass.',
      'Victoria\'s secrets aren\'t just scenic spots.\nUncover connections on Streetpass.',
      'Put your heart into it as much as your studies.\nFind a match on Streetpass.',
      'A quick bite at Mystic Market could lead to sweet talks through Streetpass.',
    ],
    testimonials: {
      'Isla, 20': `I kept seeing him at the Bibliocafé with his stack of books, and I always wondered what he was studying. Finally gathered the courage to ask him out... on Streetpass lol.`,
      'Jacob, 23': `We passed each other nearly every morning on the way to class. I thought she'd never notice me, but Streetpass proved me wrong—and now we walk to class together every morning.`,
      'Mila, 21': `I saw him working out in CARSA and was too shy to approach. Thanks to Streetpass, I landed the guy of the dreams.`,
      'Noah, 22': `I saw her sitting by the Mystic Market windows, and I couldn't stop thinking about her. Streetpass made the first move easy.`,
      'Sophia, 19': `He took the book I was going for at the library before I got a chance. Found him on Streetpass and asked if we could study together. Wish me luck!`,
      'Liam, 22': `I kept running into her in the Engineering wing and thought she was out of my league. Streetpass brought us together, and we've dating for a couple months now!`,
      'Lucas, 25': `We both took the same bus loop every day but never talked. When we matched and started talking, I realized we both wanted to talk to each other all along.`,
    },
  },
}

export const schools = Object.keys(schoolConfigs)

export const headlines = [
  'Not just another social app.\nStreetpass connects you with the people you see every day and don\'t even notice.',
  'Real connections in real time.\nOnly with real people.',
  'Serendipity is not just a city in Tanzania. Start streetpassing today.',
  'There\'s always a second chance for first impressions.',
  'Every person you cross paths with could be the beginning of a new story.',
  'Missed a connection so you wouldn\'t miss the bus?\nStreetpass has got you covered.',
  // 'They never seat you next to the right person on flights. But with Streetpass, that\'s alright.',
  // 'Love is blind? Sometimes it just needs a second look.',
  'Remember that stranger that smiled at you?\nThey\'re not a stranger anymore.\nYou\'re welcome.',
  'Streetpass turns "What if?" into "Let\'s find out!"',
  'Because "missed connection" sounds better than "I stalked you on 5 different apps".\nTry Streetpass instead.',
  'From eye contact to contact list. Streetpass makes every awkward glance count.',
  // 'With Streetpass, love is just around the corner.',
  // 'Love\'s not dead. It just needed a better GPS.',
  // 'Sometimes your soulmate is just bad at directions.',
  'The best story starts with "So I streetpassed this person..."',
  // 'Romeo & Juliet had a balcony. You have Streetpass.',
  'Turn "I should have talked to them" into "Glad I downloaded Streetpass".',
  // 'Cinderella left a shoe. You left without a clue. Find your fairytale on Streetpass!',
  'Transform "What ifs" into "Remember whens".',
  // 'Roses are red. Violets are blue.\nMissed a connection?\nStreetpass finds them for you!',
  // 'Because happily ever after starts with "Hey, have we met before?"',
  'Not all treasure is buried. Some walks right past you every day.',
  'Lost the number but not the spark? Ignite it again with Streetpass!',
  // 'Cupid\'s arrow not hitting it\'s target for you?\nMaybe it was just missing GPS.',
  'It\'s like having a rewind button for fate. Play back those missed moments with Streetpass.',
  // 'Who says lightning doesn\'t strike twice? Rekindle sparks with Streetpass.',
]

export const testimonials: Record<string, string> = {
  'Sophia, 19': `I walked past him on my way to class every morning, but we never spoke. After we Streetpassed, I finally got the courage to say hi.`,
  'Ethan, 21': `Some girl at a party told me to get this app. The next day when I checked it I saw everyone I met last night. It was cool, like having a guestlist of the party.`,
  'Jordan, 24': `We kept crossing paths at the gym, and I always wanted to ask her out but I didn't think she'd notice me. After we matched on Streetpass, I found out she was thinking the same thing I was the entire time.`,
  'Chloe, 20': `There was this guy I always saw at my gym, but I didn't know how to approach him. When he popped up on Streetpass, it gave me the perfect opportunity to start a conversation.`,
  'Aidan, 26': `I matched with someone I passed on the street during my lunch break. I thought she looked familiar, and it turns out we live in the same neighborhood! I had her over for dinner and things are going great.`,
  'Emma, 23': `I saw him at the library every week and never knew how to say hello. Thanks to Streetpass, we had our first date and hopefully more to come.`,
  'Ethan, 25': `Met my buddy Nico on this app. I've already got a girlfriend, so I just set it to looking for friends who are also into riding motorcycles. If you ride, hit me up on Streetpass!`,
  'Lucas, 23': `I didn't expect to meet someone during my morning run, but Streetpass showed me her profile after we crossed paths. We bonded over Strava.`,
  'Jamie, 19': `She sat across from me in the food court at the Nest for weeks, and honestly I just didn't have the courage to say hi. Streetpass gave me the push I needed.`,
  'Karissa, 20': `I saw her reading in the Rose Garden. Then I saw her on Streetpass and I recognized her immediately. So I swiped right, and we matched! Thank you for making this app, very cool idea!`,
  'Ryan, 24': `I saw her at the top of Burnaby Mountain during sunset, and I thought I missed my chance when I waited to long to talk to her before she drove off. We matched on Streetpass the next day!`,
  'Isla, 20': `I kept seeing him at the Bibliocafé with a stack of books, and I always wondered what he was studying. Finally gathered the courage to ask him out... on Streetpass lol.`,
}
