import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import Home from './screens/Home'
import { useEffect, } from 'react'
import { View } from 'react-native'
import Tos from './screens/Tos'
import About from './screens/About'
import Help from './screens/Help'
import NotFound from './screens/NotFound'
import Refer from './screens/Refer'
import { appStoreListing, schools } from './utils/constants'
import { useDispatch } from 'react-redux'
import { setDimensions } from './state/actions/SystemActions'

const ExternalRedirect = ({ url, }: { url: string, }) => {
  useEffect(() => {
    window.location.href = url
  }, [url])
  return null
}

export enum Screens {
  Home = '/',
  About = '/about',
  Help = '/help',
  Tos = '/tos',
  Refer = '/ref',
  NotFound = '*',
}

function App() {
  const dispatch = useDispatch()

  return (
    <>
      <Router>
        <Routes>
          <Route path={Screens.Home} element={<Home />} />
          <Route path={Screens.About} element={<About />} />
          <Route path={Screens.Help} element={<Help />} />
          <Route path={Screens.Tos} element={<Tos />} />
          <Route path={Screens.Refer} element={<Refer />} />
          <Route path={Screens.NotFound} element={<NotFound />} />
          {schools.map(school => <Route key={school} path={`/${school}`} element={<Navigate to={`/?${school}`} replace />} />)}
          <Route path='/app' element={<ExternalRedirect url={appStoreListing} />} />
        </Routes>
      </Router>
      <View
        onLayout={(event) => dispatch(setDimensions({ width: event.nativeEvent.layout.width, height: event.nativeEvent.layout.height, }))}
        style={{position: 'absolute', zIndex: -1, width: '100%', height: '100%',}}
      />
    </>
  )
}

export default App
