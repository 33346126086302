import React from 'react'

interface IBlockBoxProps {
  masterRef?: any,
  children?: React.ReactNode,
}
function BlockBox({ masterRef, children, }: IBlockBoxProps) {
  return (
    <div style={{
      position: 'absolute', width: '100%', height: '100%', minHeight: 600,
      background: 'rgba(23,25,30, 1)',
    }}>
      <div ref={masterRef} style={{position: 'absolute', zIndex: 0, width: '100%', height: '100%', overflowY: 'scroll', scrollBehavior: 'smooth',}}>
        {children}
      </div>
    </div>
  )
}

export default BlockBox
