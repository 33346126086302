import React, { } from 'react'
import NavTabBar from '../components/navTabBar'
import BlockBox from '../components/blocks/BlockBox'
import EmailBlock from '../components/blocks/EmailBlock'
import { Screens } from '../App'

export interface IHomeProps {

}
function Help({ }: IHomeProps) {
  return (
    <>
      <NavTabBar screen={Screens.Help} />

      <BlockBox>
        <EmailBlock order={0} />
      </BlockBox>
    </>
  )
}

export default Help
