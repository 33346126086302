import React from 'react'
import { View, Image, } from 'react-native'
import { useSelector } from 'react-redux'
import { IStores } from '../../state/store'
import Text from '../text'
import { ReactComponent as StreetpassColorIcon, } from '../../assets/icons/logo-color.svg'
import Block from './Block'

interface IAboutBlockProps {
  masterRef?: any,
  order: number,
}
function AboutBlock({ order, }: IAboutBlockProps) {
  const systemStore = useSelector((state: IStores) => state.systemStore)
  const { mobile, } = systemStore
  const { Colors, Fonts, Spacing, } = systemStore.mobile ? systemStore.Mobile : systemStore.Desktop
  const { School, } = useSelector((state: IStores) => state.systemStore)

  return (
    <>
      <Block order={order} style={{padding: Spacing.padding, backgroundColor: 'rgba(23,25,30, 1)',}}>
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginBottom: 16,}}>
          <StreetpassColorIcon style={{width: Fonts.xxxxl, height: Fonts.xxxxl,}} />

          {School.logo &&
            <>
              <Text style={{color: Colors.white, fontSize: Fonts.xxl, marginRight: mobile ? 8 : 16,}}>𝑿</Text>

              <View style={{
                aspectRatio: 1/1, borderRadius: Fonts.xxxxl, justifyContent: 'center', overflow: 'hidden',
                width: Fonts.xxxl, height: Fonts.xxxl, backgroundColor: School.highlight, 
              }}>
                <Image
                  source={School.logo}
                  style={{width: '100%', height: '100%',}}
                  resizeMode={'contain'}
                />
              </View>
            </>
          }
        </View>

        <View style={{flex: 5, flexDirection: 'row', justifyContent: 'center',}}>
          <Text style={{fontSize: Fonts.md, fontWeight: Fonts.welterWeight, color: Colors.white,}}>
Streetpass works by setting you up with compatible people you pass by throughout your day. Swiping and small talk not required. Live your life and let Streetpass do it's thing in the background. Working out at the gym, studying at your local café, attending a live music event - Streetpass finds the perfect moments to connect you with people wherever you go.
{`\n\n`}And Streetpass goes beyond just that. With AI, Streetpass can automatically match you with compatible people. Your personal AI assistant will learn your personality and chat to other users for you to setup dates and hangouts. It's like having a full-time AI matchmaker always working for you in the background.
{`\n\n`}Be yourself, be safe and happy streetpassing!
{`\n\n`}- Streetpass team
          </Text>
        </View>
      </Block>
    </>
  )
}

export default AboutBlock
