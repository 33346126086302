import React, { useRef, } from 'react'
import BlockBox from '../components/blocks/BlockBox'
import VideoBlock from '../components/blocks/VideoBlock'
import InfoBlock from '../components/blocks/InfoBlock'
import FooterBlock from '../components/blocks/FooterBlock'

export interface IHomeProps {

}
function Home({ }: IHomeProps) {
  const masterRef = useRef<any>(null)

  return (
    <>
      <BlockBox masterRef={masterRef}>
        <VideoBlock order={0} />
        <InfoBlock order={1} />
        <FooterBlock order={2} />
      </BlockBox>
    </>
  )
}

export default Home
