import { baseUrl, protocol, schoolConfigs, schools, } from './constants'

export const getSchoolUrlParam = (): keyof typeof schoolConfigs | null => {
  const params = new URLSearchParams(window.location.search)
  const pathSegments = window.location.pathname.split('/').filter(Boolean)
  for (const school of schools) {
    if (params.get(school) !== null) return school as keyof typeof schoolConfigs
  }
  for (const school of schools) {
    if (pathSegments.includes(school)) return school as keyof typeof schoolConfigs
  }
  return null
}

export const getIP = async () => {
  try {
    const response = await fetch('https://api.ipify.org?format=json')
    const { ip, } = await response.json()
    return ip
  } catch (e) {
    return null
  }
}

declare global {
  interface Navigator { connection?: { downlink?: number, effectiveType?: string, rtt?: number, saveData?: boolean, }, }
}
export interface UserInfo {
  IP: string,
  locale: string,
  userAgent: string,
  connection?: string,
  referer: string,
}
export const getUserInfo = async (referer: string) => {
  return {
    IP: await getIP(),
    locale: navigator.language.split('_')[0].split('-')[0],
    userAgent: navigator.userAgent,
    connection: navigator.connection?.effectiveType,
    referer: referer,
  }
}

export const generateUtm = (userInfo: UserInfo) => {
  let OS = 'null'
  if (userInfo.userAgent.includes('iPhone')) {
    const match = userInfo.userAgent.match(/OS (\d+_\d+)( like Mac OS X)?/)
    if (match && match[1]) OS = `iPhone${match[1].replace('_', ',')}`
  }
  const date = new Date()
  return `${userInfo.IP}-${userInfo.locale}-${OS}-${userInfo.connection}-${date.getTimezoneOffset()}--${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`
}

export const sendUtmMarketing = async (referer: string) => {
  const userInfo = await getUserInfo(referer)
  const utm = generateUtm(userInfo)
  try {
    await fetch(`${protocol[0]}${baseUrl}/utm-marketing`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', },
      body: JSON.stringify({ utm: utm, userInfo: userInfo, }),
    })
  } catch (e) {
  }
}
