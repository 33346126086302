import React from 'react'
import { View, Linking, } from 'react-native'
import { useSelector } from 'react-redux'
import { IStores } from '../../state/store'
import Button from '../button'
import { email } from '../../utils/constants'
import Text from '../text'
import Block from './Block'

interface IEmailBlockProps {
  masterRef?: any,
  order: number,
}
function EmailBlock({ order, }: IEmailBlockProps) {
  const systemStore = useSelector((state: IStores) => state.systemStore)
  const { mobile, } = systemStore
  const { Colors, Fonts, Spacing, } = systemStore.mobile ? systemStore.Mobile : systemStore.Desktop

  return (
    <>
      <Block order={order} style={{padding: Spacing.padding, backgroundColor: 'rgba(23,25,30, 1)',}}>
        <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center', alignItems: 'center',}}>
          <View style={{flex: mobile ? 2 : 1,}}>
            <Button
              title={'Send'}
              onPress={() => Linking.openURL(`mailto:${email}`)}
            />
          </View>
          <View style={{flex: mobile ? 3 : 5, marginLeft: 16,}}>
            <Text style={{fontSize: Fonts.md, fontWeight: Fonts.welterWeight, color: Colors.white,}}>us an email. We'll get back to you ASAP.</Text>
          </View>
        </View>
      </Block>
    </>
  )
}

export default EmailBlock
