import React, { useEffect, useState } from 'react'
import { View, Linking, Animated, TouchableOpacity, } from 'react-native'
import { useSelector } from 'react-redux'
import { IStores } from '../../state/store'
import Button from '../button'
import { appStoreListing, } from '../../utils/constants'
import Text from '../text'
import { sendUtmMarketing } from '../../utils/functions'
import Block from './Block'

const images = [
  { image: 'https://kite-case.s3.us-east-2.amazonaws.com/screenshot-1.png', thumbnail: 'https://kite-case.s3.us-east-2.amazonaws.com/screenshot-1-thumbnail.png', position: 'top', },
  { image: 'https://kite-case.s3.us-east-2.amazonaws.com/screenshot-2.png', thumbnail: 'https://kite-case.s3.us-east-2.amazonaws.com/screenshot-2-thumbnail.png', position: 'center', },
  { image: 'https://kite-case.s3.us-east-2.amazonaws.com/screenshot-3.png', thumbnail: 'https://kite-case.s3.us-east-2.amazonaws.com/screenshot-3-thumbnail.png', position: 'bottom', },
]

interface IInfoBlockProps {
  masterRef?: any,
  order: number,
}
function InfoBlock({ order, }: IInfoBlockProps) {
  const systemStore = useSelector((state: IStores) => state.systemStore)
  const { mobile, } = systemStore
  const { Colors, Fonts, Spacing, } = systemStore.mobile ? systemStore.Mobile : systemStore.Desktop
  const { School, } = useSelector((state: IStores) => state.systemStore)

  const [referer, setReferer] = useState<string | null>(null)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const ref = params.get('ref')
    if (ref) setReferer(ref)
  }, [])

  const [animations, setAnimations] = useState(Array(images.length).fill(null).map(() => new Animated.Value(1)))
  const handleFocus = (index: number, focus: boolean) => {
    Animated.timing(animations[index], {
      toValue: focus ? (mobile ? 4 : 3) : 1,
      duration: 200,
      useNativeDriver: false,
    }).start()
  }

  return (
    <>
      <Block order={order} style={{backgroundColor: '#191970',}}>
        <View style={{flex: 8, flexDirection: mobile ? 'column' : 'row', paddingTop: 8, paddingHorizontal: Spacing.paddingMd,}}>
          <View style={{flex: 1, marginRight: mobile ? 0 : 32, marginTop: mobile ? 32 : 0, justifyContent: 'center',}}>
            <Text style={{fontSize: Fonts.xxl, fontWeight: Fonts.heavyWeight, marginBottom: 8, color: Colors.white}}>How does it work?</Text>
            <Text style={{fontSize: Fonts.lg, fontWeight: Fonts.middleWeight, color: Colors.white,}}>
              Streetpass matches you with {School.name ? 'other students' : 'people'} you pass {School.name ? `by on ${School.name.toUpperCase()} campus` : `in the street`}—your phone doesn't even need to be open. Every new person you see is a streetpass waiting to happen.
              
              {`\n\n`}Connect instantly. Meet effortlessly. Streetpass will handle the rest.
            </Text>

            <View style={{marginVertical: 24,}}>
              <Button
                title={'Get the app'}
                onPress={async () => {
                  if (referer) await sendUtmMarketing(referer)
                  Linking.openURL(appStoreListing)
                }}
              />
            </View>
          </View>

          <View style={{flex: 1, flexDirection: 'row', justifyContent: 'center', paddingVertical: 32,}}>
            {images.map((item, index) => {
              return (
                <Animated.View
                  key={index}
                  onPointerEnter={() => handleFocus(index, true)}
                  onPointerLeave={() => handleFocus(index, false)}
                  style={{
                    flex: animations[index], paddingHorizontal: 4,
                  }}
                >
                  <TouchableOpacity
                    style={{width: '100%', height: '100%',}}
                    activeOpacity={1}
                    onPress={() => {}}
                  >                    
                    <div
                      onTouchStart={(e) => e.preventDefault()}
                      style={{
                        position: 'absolute', zIndex: 1, width: '100%', height: '100%',
                        pointerEvents: 'none', userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none',
                      }}
                    />
                    <img
                      src={item.thumbnail}
                      alt={''}
                      onTouchStart={(e) => e.preventDefault()}
                      style={{
                        position: 'absolute', zIndex: -2, width: '100%', height: '100%', objectFit: 'cover', objectPosition: item.position, borderRadius: 16, overflow: 'scale-down',
                        pointerEvents: 'none', userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none',
                      }}
                    />
                    <img
                      src={item.image}
                      alt={''}
                      onTouchStart={(e) => e.preventDefault()}
                      style={{
                        zIndex: -1, width: '100%', height: '100%', objectFit: 'cover', objectPosition: item.position, borderRadius: 16, overflow: 'scale-down',
                        pointerEvents: 'none', userSelect: 'none', WebkitUserSelect: 'none', MozUserSelect: 'none',
                      }}
                    />
                  </TouchableOpacity>
                </Animated.View>
              )
            })}
          </View>

        </View>
      </Block>
    </>
  )
}

export default InfoBlock
